@import "../../scss/partials/_all.scss";
@import "swiipe.portal.shared/src/components/form/FormBaseStyles.scss";

.merchant-pre-onboarding-page {
    .merchant-pre-onboarding-page__language {
        margin-top: 20px;
        max-width: $form-base-fields-width;
    }

    .merchant-pre-onboarding-page__crn-status {
        max-width: $form-base-fields-width;
    }

    .merchant-pre-onboarding-page__crn-status,
    .clearhaus-onboarding-message__main-button-warning {
        background-color: #fff3cd;
        margin-bottom: 30px;
        margin-top: 10px;
        padding: 15px 20px;
        font-style: italic;
        color: $dark-red;
        border-radius: 7px;
    }

    .merchant-pre-onboarding-page__spinner {
        background-color: white;
        height: 300px;
    }
}
