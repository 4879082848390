#email-base-template-page {
    .ddl-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .email-base-template-type {
            flex: 3 1 0px;
        }
        .language {
            margin-left: 20px;
            flex: 1 1 0px;
        }
    }

    .actions {
        margin-bottom: 20px;
        display: flex;
        background-color: #eee;
        padding: 20px 20px;

        > * {
            flex: 1 1 0;
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .image-container {
        display: flex;
        flex-wrap: wrap;
    }

    .set-is-disabled {
        margin-bottom: 20px;
        padding-top: 10px;
    }

    .name-container {
        display: flex;
        margin-bottom: 10px;

        > * {
            flex: 1 1 0;
        }

        .save-button {
            flex: 0 0 auto;
            margin-left: 20px;
        }
    }
}
