.swiipe-adder {
    .icon-addnew {
        background-image: url("../../images/icons/addnew.png");
    }

    button {
        display: flex;
        align-items: center;

        .text-element {
            font-size: 0.8em;
            color: #0e3251;
        }
    }

    &.swiipe-adder--no-padding {
        padding: 0;
        button {
            padding: 0;
        }
    }
}
