@import "../../scss/partials/all.scss";

#top-navbar {
    padding-right: 130px;
    .email {
        font-weight: bold;
    }

    .top-navbar__navigation-container {
        text-align: center;
    }
}
