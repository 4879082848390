.credentials-form-container {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;

    .buttons-container {
        .delete-btn {
            max-width: 130px;
        }
    }
}
