.modal-sms {
    .content-container {
        display: flex;

        a.link {
            font-weight: 100;
            font-size: 0.9em;
        }

        p {
            white-space: pre-line;
            text-align: left !important;

            max-width: 410px;
            line-height: 24px;
            position: relative;
            padding: 10px 20px;
            border-radius: 25px;

            font-family: "Helvetica Neue", Helvetica, sans-serif;
            font-size: 20px;
            font-weight: normal;

            &:before,
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                height: 25px;
            }

            background: #e5e5ea;
            color: black;
            align-self: flex-start;

            &:before {
                left: -7px;
                border-left: 20px solid #e5e5ea;
                border-bottom-right-radius: 16px 14px;
            }

            &:after {
                left: -14px;
                border-left: 14px solid white;
                border-bottom-right-radius: 10px;
            }
        }
    }
}
