#health-status-page {
    .health-status-page__main-action-container {
        display: flex;

        > * {
            flex: 1 1 0px;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .health-status-page__payment-section-container {
        margin-top: 12px;
    }

    .health-status-page__loading-content {
        height: 100px;
    }
}
