#template-page {
    .save-button {
    }

    .subject {
        margin-bottom: 20px;
    }

    .actions {
        margin-bottom: 20px;
        background-color: #eee;
        padding: 20px 20px;
        display: flex;

        > * {
            flex: 1 1 0;
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .image-container {
        display: flex;
        flex-wrap: wrap;
    }

    .ddl-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .template-type {
            flex: 3 1 0px;
        }
        .language {
            margin-left: 20px;
            margin-right: 20px;
            flex: 1 1 0px;
        }
    }

    .send-test-email {
        align-self: flex-end;
    }

    .address-container {
        display: flex;
        margin-bottom: 10px;

        > * {
            flex: 1 1 0;
            margin-left: 20px;
        }

        > *:first-child {
            margin-left: 0;
        }
    }

    .email-base-container {
        margin-bottom: 20px;

        display: flex;
        flex-wrap: wrap;

        > * {
            flex: 1 1 0px;
        }

        .show-code {
            flex: 0 0 300px;
            margin-left: 20px;
            align-self: flex-end;
        }

        .base-template-code {
            margin-top: 20px;
            flex: 0 0 100%;
        }
    }
}
