.apple-pay-swiipe-certificates-setup-page {
    .apple-pay-setup {
        &__step-description {
            font-size: 15px;
            margin-bottom: 10px;

            b{
                font-weight: 600;
            }
        }

        &__download-btn {
            width: auto;
        }

        &__file-upload {
            max-width: 400px;
        }

        &__generate-signing-request {
            button {
                width: auto;
            }
            span {
                color: red;
            }
        }
    }

    .apple-pay-has-been-setup {
        &__message {
            font-size: 20px;
            margin-bottom: 10px;
        }

        &__additional-info {
            font-size: 15px;
            margin-top: 20px;
        }

        &__renew-button {
            width: auto;
        }
    }
}