.tracked-time-list {
    .tracked-time-list__show-all {
        padding: 20px 0;
    }
    p {
        margin: 0;
        padding: 0;
        font-weight: 100;

        b {
            font-weight: 900;
        }
    }
    table {
        border-spacing: 30px 10px;
        border-collapse: separate;
    }
    .tracked-time-list__actions {
        display: flex;
    }
    .tracked-time-list__actions-edit {
        margin-right: 1cap;
    }
    .tracked-time-list__add-new {
        min-width: 120px;
        text-align: left;
        margin: 10px 30px;
    }
}
