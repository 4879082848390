#release-note-display {
    font-size: 1.2em;
    position: relative;

    .version {
        font-weight: 600;
        margin-bottom: 0;
    }

    .date {
        font-size: 0.9em;
        font-weight: 300;
    }

    .language-switcher {
        display: flex;
        justify-content: flex-end;

        .language-option {
            cursor: pointer;
            border: 1px solid;
            border-radius: 5px;
            padding: 3px 5px;
            font-size: 11px;
            margin-left: 3px;

            &.active,
            &:hover {
                background-color: #0000001c;
            }
        }
    }

    .edit-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 35px;
    }
}
