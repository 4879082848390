.data-row {
    &--editable {
        cursor: pointer;
    }
    &--editable-empty {
        text-decoration: underline;
    }

    .data-row__form {
        display: flex;
        flex: 1;
        padding-right: 15px;

        .data-row__textarea {
            width: 100%;
            margin: 0 6px;
            flex: 1 0 300px;
        }
        .data-row__input {
            margin: 0 6px;

            input {
                height: 22px;
                font-size: 13px;
                padding-top: 2px;
            }
        }
        .data-row__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-self: center;
            flex-direction: column;
            height: 75px;

            &--in-row {
                flex-direction: row;
                height: auto;
                flex: 0 1 150px;
            }

            .data-row__save-button {
                width: 60px;
                align-self: center;
            }
            .data-row__cancel-button {
                text-decoration: underline;
            }
            .data-row__delete-button {
                color: #cc0000;
                text-decoration: underline;
            }
        }
    }
}
