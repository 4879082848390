.checkbox-hint-container {
    display: inline-block;

    .hint-icon {
        background: url("../../../images/icons/info-inverted.svg") no-repeat;
        height: 16px;
        width: 16px;
        display: inline-block;
        vertical-align: sub;
        margin-left: 5px;
        cursor: pointer;
    }

    .hint-text {
        position: absolute;
        background: #383838f2;
        color: #ffffff;
        padding: 10px;
        left: -15px;
        top: 30px;
        z-index: 999;
        border-radius: 5px;
        font-style: italic;
        font-size: 12px;

        &:before {
            content: "";
            border: 10px solid transparent;
            border-bottom: 10px solid #383838f2;
            position: absolute;
            top: -20px;
        }
    }
}
