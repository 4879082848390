#piq_config_generator-page {
    max-width: 80em;
    margin: auto;
    .loaded-data-container {
        margin-top: 2rem;
        padding-top: 1em;
        border-top: solid #1a496d91 2px;
    }

    .configs-container {
        margin-top: 3em;
    }

    .save-button {
        margin-top: 10px;
    }

    .form-label-group {
        padding-top: 10px;
    }

    .warning-text {
        font-size: larger;
        color: red;
    }

    .code-editor {
        padding-bottom: 2.5em;
    }

    .code-editor label {
        font-weight: bold;
    }
}
