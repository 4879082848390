.modal-merchant-offer-preview {
    position: relative;
    .modal-merchant-offer-preview__close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
        background-color: white;
    }
    .modal-dialog {
        max-width: 100% !important;
        height: auto !important;
        margin: 0 !important;
    }
}
