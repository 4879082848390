#test-run-dashboard-page {
    .outer {
        display: flex;
        flex-direction: row;
        min-height: 200px;
    }
    .category {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
    }
}

.test-run-container {
    &.failed .closed-content {
        background-color: red;
    }
    margin: 10px 0;
    border-radius: 3px;
    border: solid 1px black;

    .column-container {
        display: flex;
        .column {
            flex: 1;

            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                margin-left: 10px;
            }
        }
    }

    .closed-content {
        cursor: pointer;
        background-color: lightgreen;
        display: flex;
        align-items: center;
        padding: 10px;

        .time {
            width: 60px;
        }

        .space {
            flex: 1;
        }

        .name {
            font-size: 16px;
        }
        .platform {
            font-size: 12px;
            background-color: white;
            border-radius: 10px;
            padding: 3px;
            margin-left: 10px;
            border: solid 1px black;
        }
    }

    .open-content {
        margin-top: 5px;
        padding: 10px;
    }

    &.open {
    }

    .percent {
        width: 50px;
        text-align: right;
    }

    .test-run-details-property {
        display: flex;
        padding: 3px 0;
        .name {
            flex: 1;
        }
    }
}
