.swiipe-file-upload {
    width: 100%;
    background: #f2f2f282;
    color: #7f7f7f;
    position: relative;
    padding: 10px 25px 10px 25px;
    display: flex;

    span {
        text-align: center;
        margin-top: 5px;
        margin-right: 10px;
    }

    .upload-btn {
        width: 50%;
    }

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
}
