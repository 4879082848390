@import "../../scss/partials/_all.scss";
@import "swiipe.portal.shared/src/components/form/FormBaseStyles.scss";

.merchant-pre-onboarding-page {
    .merchant-pre-onboarding__comment-bubble {
        width: 15px;
        margin-left: 5px;
        margin-bottom: 2px;
    }
    .merchant-pre-onboarding__data-actions__inner {
        display: flex;
        align-items: center;
    }
    .merchant-pre-onboarding__data-actions__navigate {
        text-decoration: underline;
        color: #23638e;
    }
}
