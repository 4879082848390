@import "../../scss/partials/all";

.add-webshops-form {
    .add_webshops-form__icon-buttons {
        display: flex;
        > * {
            margin-right: 20px;
        }
    }
}
