@import "../../../scss/partials/all.scss";

.terms-example-form {
    display: flex;
    flex-direction: column;

    .terms-example-form__save-container {
        margin-top: 20px;
        width: 300px;
        align-self: flex-end;
    }
}
