#merchant-details-page {
    max-width: 80em;
    margin: auto;
    .merchant-details-container {
        border-bottom: solid #1a496d91 1px;
        margin: 10px 0 10px 0;
    }
    .data-row {
        display: flex;
        margin-bottom: 10px;
        .copy-btn {
            max-width: 3rem;
            margin-left: 10px;
        }
    }
}
