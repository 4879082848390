.session-events-table {
    margin-top: 30px;

    .session-events-table__line {
        width: 100%;
        margin-bottom: 5px;
        display: flex;

        .session-events-table__line-column {
            flex: 1;
            max-width: 100px;

            &--medium {
                flex: 2;
                max-width: 200px;
            }
            &--large {
                flex: 3;
                max-width: 300px;
            }
        }

        &--first {
            margin-top: 10px;
        }
        &--italic {
            font-style: italic;
        }
        &--bolder {
            &-black {
                color: black;
                font-weight: bolder;
            }
            &-green {
                color: green;
                font-weight: bolder;
            }
            &-blue {
                color: blue;
                font-weight: bolder;
            }
            &-orange {
                color: orange;
                font-weight: bolder;
            }
            &-red {
                color: red;
                font-weight: bolder;
            }
        }
    }
}
