@import "../../scss/partials/_all.scss";

.webshop-api-tester-page {
    .webshop-asset-editor textarea {
        min-height: 300px;
    }

    .webshop-api-tester-page__error {
        color: red;
    }

    .button-container {
        display: flex;
        > * {
            flex: 1 1 0;
            margin: 0 20px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
