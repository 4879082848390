@import "../../scss/partials/all.scss";

$outer-height: 24px;
$inner-height: 10px;
.tracked-time-counter {
    display: flex;
    flex-direction: column;
    border: solid 1px $gray-border;
    padding: 5px 15px;
    border-radius: 10px;
    max-width: 250px;
    margin: 0 10px;

    .tracked-time-counter__name {
        max-width: 100%;
        font-size: 12px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        line-break: strict;
        white-space: nowrap;
        overflow: hidden;
    }

    .tracked-time-counter__control {
        display: flex;
        justify-content: center;
        font-size: 20px;
        align-items: center;
    }

    .tracked-time-counter__control-actions {
        display: flex;
        margin-left: 20px;
    }

    .tracked-time-counter__control-play {
        cursor: pointer;
        display: flex;
        width: $outer-height;
        height: $outer-height;
        justify-content: center;
        align-items: center;
        .tracked-time-counter__control-play-inner {
            width: 0;
            height: 0;
            border-top: $inner-height / 2 solid transparent;
            border-bottom: $inner-height / 2 solid transparent;
            border-left: $inner-height solid $blue-header;
        }
    }

    .tracked-time-counter__control-pause {
        cursor: pointer;
        display: flex;
        width: $outer-height;
        height: $outer-height;
        justify-content: center;
        align-items: center;

        .tracked-time-counter__control-pause-1 {
            width: ($inner-height / 2) - 1px;
            height: $inner-height;
            background-color: $blue-header;
            margin-right: 2px;
        }
        .tracked-time-counter__control-pause-2 {
            width: ($inner-height / 2) - 1px;
            height: $inner-height;
            background-color: $blue-header;
        }
    }

    .tracked-time-counter__control-stop {
        margin-left: 5px;
        visibility: hidden;
        cursor: pointer;
        width: $outer-height;
        height: $outer-height;
        border-radius: $outer-height;
        border: solid 1px $red-error;
        padding: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .tracked-time-counter__control-stop-inner {
            width: $inner-height;
            height: $inner-height;
            background-color: $red-error;
        }

        &.tracked-time-counter__control-stop--show {
            visibility: visible;
        }
    }
}
