@import "../../../scss/partials/all.scss";

.terms-template-form {
    .terms-template-form__save-container {
        margin-bottom: 20px;
        display: flex;
    }
    .terms-template-form__is-file {
        margin: 0;
        align-self: center;
    }
    .terms-template-form__content {
        margin-bottom: 20px;
    }
    .terms-template-form__content--hide {
        display: none;
    }
    .terms-template-form__hide-content-button {
        margin-left: 30px;
        max-width: 200px;
    }
    .terms-template-form__save-button {
        margin-left: 50px;
    }

    .terms-template-form__base-template-container {
        margin-bottom: 20px;

        display: flex;
        flex-wrap: wrap;

        > * {
            flex: 1 1 0px;
        }

        .terms-template-form__base-template-show-code {
            flex: 0 0 300px;
            margin-left: 20px;
            align-self: flex-end;
        }

        .terms-template-form__base-template-code {
            margin-top: 20px;
            flex: 0 0 100%;
        }
    }

    .terms-template-form__language-switcher {
        max-width: 300px;
    }

    .terms-template-form__translations-container {
        margin-top: 30px;

        .terms-template-form__translations-actions-container {
            display: flex;
            align-items: flex-end;

            .terms-template-form__language-switcher {
                flex: 1 1 auto;
            }
            .terms-template-form__translations-fill-blanks {
                flex: 0 0 auto;
                margin-left: 30px;
            }
        }
    }

    .terms-template-form__files-container {
        margin-top: 30px;

        .terms-template-form__files-file-is-internal {
            margin-top: 30px;
        }
    }

    .terms-template-form__translation-row {
        display: flex;
        margin-top: 20px;

        &.terms-template-form__translation-row--delete {
            color: red;
        }

        .terms-template-form__translation-row-key {
            flex: 0 0 400px;
            align-self: center;
        }
        .terms-template-form__translation-row-value {
            flex: 1 1 0;
        }

        .terms-template-form__translation-row-no-fallback {
            position: static;
            flex: 0 0 auto;
            margin-left: 20px;
        }
    }
}
