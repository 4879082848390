@import "partials/all.scss";
@import "swiipe.portal.shared/src/styles/sharedIcons.scss";

.swiipe-outer-container {
    background: url(../images/background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;

    font: 100% $font-stack;
    background-color: transparent;
    color: $blue-main;
    overflow: hidden;
}

.swiipe-outer-container,
.swiipe-modal-container {
    .pointer {
        cursor: pointer;
    }

    .top-separator {
        position: relative;
        margin-top: 2rem;

        &:before {
            display: block;
            position: absolute;
            content: "";
            top: -1rem;
            width: 100%;
            border: 1px solid $gray-border;
            opacity: 0.4;
        }
    }

    .swiipe-checkbox {
        input.custom-control-input:checked:checked ~ label.custom-control-label::before {
            background-color: $blue-header;
            border-color: $blue-header;
        }

        label.custom-control-label {
            &:before {
                border-radius: 0;
                top: 0.13rem;
            }

            &:after {
                top: 0.13rem;
            }
        }
    }

    input,
    select,
    button {
        border-radius: $input-border-radius;
    }

    small {
        font-size: 100%;
    }

    #app-container {
        padding: 0px;
        min-height: 100%;

        #main-col {
        }

        #wrapper {
            /* wrapper id is only present if the sidebar is present */
            min-height: 100%;

            #main-col {
                transition: margin-left 0.5s;
                margin-left: $sidebar-width;

                @media screen and (max-width: $breakpoint-mobile-m1) {
                    /*margin-left: $sidebar-visible;*/
                    margin-left: 0px;
                }
            }

            .form-container {
                @media screen and (max-width: 700px) {
                    width: 100%;
                }

                bottom: 7%;
            }
        }

        .navbar {
            flex-flow: row nowrap;
            padding-right: 1rem;

            @media screen and (max-width: $breakpoint-mobile-m1) {
                #navbar-toggle-trigger {
                }

                .navbar-brand {
                    padding: 0px;
                    margin: 0 auto;
                }
            }

            .navbar-nav {
                margin: 0px;

                .navbar-dropdown {
                    .dropdown-toggle {
                        span.icon {
                            display: inline-block;
                        }

                        &::after {
                            vertical-align: sub;
                            margin-left: 0.5rem;
                            display: unset;
                        }
                    }

                    .dropdown-menu {
                        position: absolute;
                        display: block;
                        transition: 0s;
                        transform: translateY(-100%);
                        z-index: -999;
                        border-radius: 0 0 5px 5px;
                        min-width: fit-content;
                        padding: unset;
                        margin: unset;

                        &.show {
                            border: 1px solid $gray-light;
                            transform: translateY(0%);
                            z-index: 1;

                            @media screen and (min-width: $breakpoint-tables) {
                                min-width: 140px;
                            }
                        }

                        .dropdown-item {
                            padding: 10px;
                            position: relative;

                            &:not(:first-child):before {
                                display: block;
                                position: absolute;
                                content: "";
                                top: 0;
                                left: 20%;
                                width: 60%;
                                border: 1px solid $gray-light;
                                opacity: 0.4;
                            }

                            &:active {
                                background-color: lightgrey !important;
                                border-radius: 0 0 5px 5px;
                            }

                            &:hover,
                            &:focus {
                                border-radius: 0 0 5px 5px;
                            }

                            &:focus {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        #sidebar {
            left: 0px;
            width: $sidebar-width;
            position: fixed;
            height: 100%;
            padding-top: $nav-menu-height;
            background-color: $blue-main;
            color: white;
            font-size: 11px;

            @media screen and (max-width: $breakpoint-mobile-m1) {
                & {
                    display: none;
                }
            }

            .router-link-exact-active {
                background-color: #e0e668;
                color: #1a496d;
            }

            ul {
                padding: 0;
                list-style: none;
                text-align: center;

                li {
                    padding: 10px;

                    .icon {
                        margin: auto;
                    }

                    .submenu {
                        position: fixed;
                        width: $submenu-width;
                        height: 100%;
                        background-color: white;
                        opacity: 0.5;
                        top: 0;
                        left: $sidebar-width;

                        ul {
                            width: $submenu-width;
                            top: 185px; /* TODO make dynamic height */
                            li {
                                height: 47px;
                                color: $blue-main;
                                font-size: 13px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        #sidebar-mobile-overlay {
            position: absolute;
            height: 100vh;
            width: 100vw;
            z-index: 2;
        }

        #sidebar-collapse.collapsing {
            transition: none;
        }

        #sidebar-mobile {
            @media screen and (min-width: $breakpoint-mobile) {
                display: none;
            }

            display: flex !important;
            height: 100%;
            background-color: $blue-main;
            z-index: 2;
            align-items: center;
            color: white;

            ul {
                list-style: none;
                text-align: center;
                padding: 0;
                margin: auto;

                .icon {
                    margin: 0 auto;
                }
            }

            li:not(:first-child) {
                position: relative;
                padding-top: 1rem;

                &:before {
                    display: block;
                    position: absolute;
                    content: "";
                    width: 50%;
                    top: 0;
                    left: 25%;
                    border: 0.5px solid white;
                }
            }

            li:not(:last-child) {
                padding-bottom: 1rem;
            }

            li p {
                margin-bottom: 0;
            }

            #close-sidebar {
                position: fixed;
                top: 0;
                left: 0;
                padding: 0.75rem;
            }

            .navbar-toggler-icon {
                height: 18px;
                width: 20px;
                background: url("../images/icons/close-white.png");
                background-size: 20px 18px;
            }
        }

        #main {
            margin-bottom: 4rem;
        }

        #main-container {
            padding: 0px 15px;
            max-width: $main-container-width;
            margin: auto;
            min-height: 200px;

            .title {
                color: $blue-main;
                font-size: 19px;
            }

            .pw-requirement {
                background-color: $gray-background;
                padding: 8px 16px;

                .dialog-title {
                    color: $blue-second;
                    font-weight: 600;
                    font-size: 14px;
                }

                .dialog-text {
                    font-weight: 400;
                    color: $blue-second;
                    font-size: 13px;
                }
            }

            .page-header {
                padding-top: 15px;
                margin-bottom: 30px;

                h3 {
                    font-size: 16px;
                    color: $blue-main;
                }

                h5 {
                    color: $blue-main;
                }
            }

            .page {
                padding-bottom: 15px;

                .page-body {
                    background-color: white;
                    padding: 15px 30px;
                    font-size: 13px;
                    color: $blue-logo;

                    &.guide {
                        .page-top-container {
                            p {
                                font-size: 16px;
                                color: $blue-header;
                                text-transform: uppercase;
                            }
                        }

                        a {
                            color: $blue-header;
                            text-decoration: underline dotted;
                            text-decoration-color: $blue-header;
                        }

                        a:hover {
                            color: $blue-link-hover;
                            text-decoration-color: $blue-link-hover;
                        }

                        img {
                            width: 100%;
                        }

                        .guide-section {
                            margin-bottom: 1.5rem;
                        }

                        img,
                        video-container {
                            margin-bottom: 1rem;
                        }

                        .video-container {
                            position: relative;
                            padding-bottom: 56.25%;
                            padding-top: 30px;
                            height: 0;
                            overflow: hidden;

                            iframe,
                            .video-container object,
                            .video-container embed {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        ul.dash {
                            list-style: none;
                            margin-left: 0;
                            padding-left: 1em;
                        }

                        ul.dash > li:before {
                            display: block;
                            float: left;
                            content: "-";
                            color: $blue-logo;
                            width: 1em;
                            margin-left: -1em;
                        }

                        h2 {
                            font-size: 18px;
                            color: $blue-logo;
                        }

                        h3 {
                            font-size: 16px;
                            color: $blue-logo;
                            font-weight: 700;
                        }

                        p {
                            font-size: 16px;
                            color: $blue-logo;
                        }
                    }

                    .item-container {
                        min-height: 64px; /*Button height + 1rem*/
                        align-items: center;
                    }

                    &.transparent {
                        background-color: transparent;
                        padding: 0;
                    }

                    .page-icon-container {
                        margin-bottom: 20px;

                        .page-icon {
                            margin: auto;
                        }
                    }

                    .infobox {
                        min-height: $infobox-min-height;
                        background-color: white;
                        margin: 20px 0;
                        padding: 15px;

                        @media screen and (max-width: $breakpoint-tables) {
                            .adaptive-icon {
                                align-self: center;
                            }

                            .icon-sub-text:not(.show-always) {
                                display: none;
                            }

                            .show-always.icon-sub-text {
                                font-size: 0.8em;
                            }
                        }

                        .icon-container {
                            margin: 0;
                            text-align: center;
                            text-transform: uppercase;
                            font-size: 14px;
                            color: $blue-main;
                        }

                        .info-container {
                            margin: 0;
                            font-weight: 400;
                            font-size: 17px;
                            color: $blue-header;
                            //padding-right: $infobox-padding-right;
                            align-self: center;

                            br {
                                display: block;
                                content: "";
                                margin-top: 10px;
                            }

                            .hidden {
                                display: none;
                            }

                            .edit-form {
                                margin-top: 10px;

                                input {
                                    border-radius: 3%;
                                }

                                select {
                                    font-weight: bold;
                                }
                            }

                            .personal-checkbox {
                                position: relative;
                                left: 25px;
                                font-size: 13px;
                                font-weight: 400;
                                color: $blue-main;

                                input:checked ~ label::before {
                                    background-color: $blue-header;
                                    border-color: $blue-header;
                                }

                                label {
                                    &:before {
                                        border-radius: 0;
                                        top: 0.13rem;
                                    }

                                    &:after {
                                        top: 0.13rem;
                                    }
                                }
                            }

                            .button-container {
                                margin-top: 0.5rem;
                                padding-left: 25px;

                                button.edit-form-submit {
                                    background-color: $blue-header;
                                    text-transform: uppercase;
                                }

                                button.edit-form-delete {
                                    color: $red-error;
                                    border-color: $red-error;
                                    background-color: white !important;
                                    text-transform: uppercase;
                                }
                            }

                            .inner-info-container {
                                position: relative;

                                .icon-add-new {
                                    margin: 0 auto;
                                }

                                &:not(:first-child) {
                                    margin: 2rem 0;

                                    .separator {
                                        position: relative;

                                        &:before {
                                            display: block;
                                            position: absolute;
                                            content: "";
                                            top: -1rem;
                                            left: 0px;
                                            width: calc(100%);
                                            border: 1px solid $gray-border;
                                            opacity: 0.4;
                                        }
                                    }
                                }

                                .confirm-dialog {
                                    background-color: $gray-background;
                                    margin-left: -25px;
                                    padding: 10px 35px;

                                    .dialog-title {
                                        font-weight: 600;
                                        font-size: 16px;

                                        &.danger {
                                            color: $red-error;
                                        }
                                    }

                                    .dialog-text {
                                        font-weight: 400;
                                        color: $blue-main;
                                        font-size: 13px;
                                    }

                                    button {
                                        width: 100%;
                                    }
                                }

                                .border-container {
                                    border-left: 10px solid $gray-border;
                                    padding-left: 15px;

                                    .edit-form {
                                        margin-bottom: 1rem;
                                    }

                                    @media screen and (max-width: 576px) {
                                        font-size: smaller;
                                    }

                                    &.default {
                                        border-left: 10px solid $green-primary;
                                    }

                                    &.noEdit {
                                        border-left: 10px solid $blue-second;
                                    }

                                    .edit-form {
                                        .row:last-child {
                                            .form-group {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                .icon.icon-close-blue {
                                    position: absolute;
                                    top: 0;
                                    right: $infobox-padding-right;
                                }

                                .close-form {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    width: 19px;
                                    height: 19px;
                                    cursor: pointer;
                                }

                                .close-form:before,
                                .close-form:after {
                                    position: absolute;
                                    left: 7px;
                                    content: " ";
                                    height: 19px;
                                    width: 5px;
                                    border-width: 0px;
                                    border-radius: 3px;
                                    background-color: $blue-header;
                                }

                                .close-form:before {
                                    transform: rotate(45deg);
                                }

                                .close-form:after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }

                .terms-page {
                    padding-top: 30px;

                    th,
                    tr {
                        border: 1px solid black;
                    }
                }
            }

            .center-btn {
                text-align: center;
            }

            input {
                border-color: $gray-border;
            }

            .center-form {
                width: $center-form-width-desktop;
                /*border: 1px solid #cccccc;*/
                background-color: #ffffff;
                margin: auto;
                margin-top: 80px;
                margin-bottom: 80px;
                padding: 20px 70px;
                /*-moz-box-shadow: 0 0 3px #ccc;*/
                /*-webkit-box-shadow: 0 0 3px #ccc;*/
                /*box-shadow: 0 0 3px #ccc;*/
                border-radius: 0px;

                @media screen and (max-width: $breakpoint-mobile-m1) {
                    padding: 2rem;
                    width: 100%;
                }

                .form-head {
                    img {
                        width: 50%;
                    }

                    margin-bottom: 2rem;
                }

                button,
                input {
                    border-radius: 3%;
                    font-size: 16px;
                }

                button {
                    text-transform: uppercase;
                }

                label,
                p,
                li {
                    color: $gray-font;
                    font-size: 13px;
                }

                h2 {
                    font-size: 19px;
                    font-weight: 600;
                    color: $blue-main;
                }

                a p {
                    font-size: 14px;
                    text-decoration: underline;
                    color: $blue-header;
                }
            }

            .message-box {
                width: $message-box-width-desktop;
                margin-top: $main-container-top-margin;
                //border: 1px solid #CCCCCC;
                max-width: 736px;
                min-height: 150px;
                background-color: #ffffff;
                //-moz-box-shadow: 0 0 3px #ccc;
                //-webkit-box-shadow: 0 0 3px #ccc;
                //box-shadow: 0 0 3px #ccc;
                border-radius: 0px;

                @media screen and (max-width: 968px) {
                    width: 100%;
                }

                &:not(:first-child) {
                    margin-top: 2rem;
                }

                .message-box-head {
                    height: $header-height;
                    line-height: $header-height;
                    background-color: $blue-header;

                    p {
                        color: $yellow-selected;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 16px;
                    }
                }

                .message-box-body {
                    padding: 50px 50px;
                    border-radius: 3%;
                    color: $blue-main;
                    font-size: 16px;

                    &.center {
                        text-align: center;
                    }

                    @media screen and (max-width: 968px) {
                        margin: 0;
                    }

                    .small-text {
                        font-size: 14px;
                    }

                    .right-link {
                        text-align: right;

                        a {
                            color: $blue-header;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }

                button,
                input {
                    border-radius: 3%;
                }
            }
        }

        nav {
            background-color: white;
            height: $nav-bar-height;

            li.nav-item {
                .icon {
                    display: inline-block;
                }
            }

            .navbar-brand {
                padding: 0px 15px;
                height: inherit;

                img {
                    height: 100%;

                    @media screen and (max-width: $breakpoint-mobile-m1) {
                        height: 80%;
                        margin-top: 0.5rem;
                    }
                }
            }
        }

        .img-center {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .blue-logo {
        color: $blue-logo;
    }

    .bg-white-75 {
        background-color: #ffffff8a !important;
    }

    .opacity-100 {
        opacity: 1 !important;

        &:hover {
            opacity: 0.9 !important;
        }
    }

    .xx-large {
        font-size: xx-large !important;
    }

    .no-sidebar {
        .footer {
            width: 100%;
        }
    }

    .pseudo-footer {
        height: $footer-height;
        width: 100%;

        @media screen and (max-width: $breakpoint-mobile-m1) {
            height: $footer-height-mobile;
        }
    }

    .footer {
        &.show {
            transform: translateY(0%);
        }

        @media screen and (max-width: $breakpoint-mobile-m1) {
            width: 100%;
            height: $footer-height-mobile;
        }

        background-color: white;
        height: $footer-height;
        min-height: fit-content;
        font-size: 11px;
        color: $gray-font;
        text-align: center;
        position: fixed;
        transition: transform 0.5s;
        transform: translateY(100%);
        bottom: 0;
        width: calc(100% - 120px);
        display: flex;
        align-items: center;

        a {
            color: $gray-font;

            &:not(.social-link-footer) {
                border-bottom: 1px dotted #797676;
            }

            &:hover:not(.social-link-footer) {
                text-decoration: none;
                color: #b3b3b3;
                border-bottom: 1px dotted #797676;
            }
        }

        ul {
            width: 100%;
        }

        li {
            @media screen and (max-width: 986px) {
                font-size: smaller;
                padding-top: 6px;
                padding-bottom: 6px;
            }

            p {
                margin-bottom: 0px;
            }

            span {
                transition: 0.5s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-mobile) {
        /*no selected highlight input mobile*/
        .router-link-exact-active {
            // .icon-ecommerce-account-white {
            //     background: url("../images/icons/ecommerce-account-icon-blue.png") !important;
            //     background-size: 30px !important;
            // }
        }

        // .icon-business-white-menu {
        //     background: url("../images/icons/business-white.png") !important;
        //     background-size: 30px !important;
        // }
    }
    /*Icon definitions*/
    .icon {
        &.icon-show {
            height: 24px;
            width: 24px;
            background: url("../images/icons/show.png");
            background-size: 24px;
        }

        &.icon-hide {
            height: 24px;
            width: 24px;
            background: url("../images/icons/hide.png");
            background-size: 24px;
        }

        &.icon-roleswitch-merchant {
            height: 35px;
            width: 35px;
            background: url("../images/icons/ecommerce-account-icon-blue.png");
            background-size: 35px;
        }

        &.icon-roleswitch-partner {
            height: 35px;
            width: 35px;
            background: url("../images/icons/partner-account-blue-new.png");
            background-size: 35px;
        }

        &.icon-roleswitch-seall {
            height: 35px;
            width: 35px;
            background: url("../images/icons/see-all-blue.png");
            background-size: 35px;
        }

        &.icon-nav-logout {
            height: 35px;
            width: 35px;
            background: url("../images/icons/logout-blue.png");
            background-size: 35px;
        }

        &.icon-nav-user {
            height: 35px;
            width: 35px;
            background: url("../images/icons/user-navibar.png");
            background-size: 35px;
        }

        &.icon-invoice {
            height: 45px;
            width: 45px;
            margin: 0 auto;
            background: url("../images/icons/blue_and_green/invoice.png");
            background-size: 45px;
        }
    }

    #table-of-content {
        margin-top: 30px;

        ol {
            list-style-type: none;
            font-weight: 700;
            padding: 0;
            color: $blue-main;

            a {
                color: $blue-second;
            }

            ul {
                list-style-type: none;
                font-weight: 400;
            }
        }
    }

    .chapter {
        margin-top: 30px;

        &#version {
            margin-bottom: 40px;
            text-align: right;
        }

        .term-header {
            font-size: 14px;
            font-weight: 700;
            color: $blue-logo;
            margin-bottom: 15px;
        }

        .term-body {
            font-size: 13px;
            color: $blue-logo;
            overflow: hidden;

            h3 {
                font-size: 13px;
                font-weight: 600;
                color: $blue-logo;
            }

            ol {
                &.bullet ul li {
                    display: list-item;
                    list-style-type: circle;
                    margin-left: 20px;
                }

                padding-left: 10px;
            }

            &:not(.bullet) li {
                display: flex;

                &:before {
                    @extend h3;
                    content: attr(data-prefix);
                    margin-right: 10px;
                }
                a {
                    display: contents;
                }
            }

            table,
            td,
            th {
                border: 1px solid #ddd;
                text-align: left;
            }

            table {
                border-collapse: collapse;
                width: 100%;
            }

            th,
            td {
                padding: 15px;
            }
        }
    }
}

.swiipe-btn {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    height: $sw-btn-height;
    background-color: $blue-header !important;

    &.swiipe-btn-white {
        background-color: white !important;
        border-color: $gray-light;
        color: $gray-font;
        font-weight: 400;
        font-size: 14px !important;
    }
}
