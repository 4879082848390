.recalculate-page-wrapper {
    .recalculate-form-container {
        background-color: white;
        padding: 20px;
        margin-bottom: 20px;
    }

    .btn-submit {
        width: 170px;
    }
}
