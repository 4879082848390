#release-note-editor {
    .release-note-form {
        .input-container {
            margin-top: 20px;
        }

        .buttons {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            button {
                width: 130px;
                height: 40px;
            }

            .save-delete {
                display: flex;

                .delete-btn {
                    color: #af0000;
                    border-color: #af0000;
                    margin-right: 10px;
                }
            }
        }
    }
}
