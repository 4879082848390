#customer-contacts-page {
    .main-form {
        padding: 0 20%;
    }

    .select-plan {
        margin-bottom: 60px;
    }

    .btn-save {
        max-width: 280px;
        margin: 0 auto;
    }
}