@import "../../scss/partials/_all.scss";

.merchant-pre-onboarding-details-page {
    .merchant-pre-onboarding-details-page__actions {
        flex-direction: column;
        align-items: flex-end;

        > * {
            width: inherit;
        }
    }

    .merchant-pre-onboarding-details-page__info-box {
        background-color: #fbf4e1;
        border-radius: 10px;
        padding: 15px 20px;
        margin-bottom: 20px;
        font-size: 14px;
        font-style: italic;

        &--draft {
            background-color: #eef0f6;
        }
        &--signed {
            background-color: #e0eefa;
        }
    }

    .merchant-pre-onboarding-details-page__top-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .merchant-pre-onboarding-details-page__top-left-add-comment {
            padding-bottom: 20px;
        }
        margin-bottom: 20px;

        .merchant-pre-onboarding-details-page__new-comment-headline {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .merchant-pre-onboarding-details-page__top-right-service {
        text-align: right;
        margin-bottom: 5px;
    }

    .merchant-pre-onboarding-details-page__comments {
        font-size: 15px;
        background: #f2f2f27f;
        padding: 20px;
        padding-bottom: 10px;
        color: #797676;
        width: 100%;
    }

    .merchant-pre-onboarding-details-page__edit-comment {
        width: 100%;
        margin-bottom: 20px;
        .merchant-pre-onboarding-details-page__edit-comment-input {
        }
        .merchant-pre-onboarding-details-page__edit-comment-actions {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            > * {
                flex: 0 0 auto;
                width: inherit;
            }
        }
    }

    .merchant-pre-onboarding-details-page__comment-text {
        font-style: italic;
        font-size: 14px;
        color: $blue-main;
    }

    .merchant-pre-onboarding-details-page__comment-data {
        display: flex;
        margin-top: 1em;
        color: $blue-main;

        .merchant-pre-onboarding-details-page__comment-created {
            flex: 1 1 auto;
            margin-left: 3px;
        }

        .merchant-pre-onboarding-details-page__comment-actions {
            display: flex;
        }
    }

    .merchant-pre-onboarding-details-page__status-outer {
        display: flex;
        justify-content: flex-end;
    }

    .merchant-pre-onboarding-details-page__status {
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 16px;
        clear: both;

        background-color: #fbf4e1;

        &--draft {
            background-color: #eef0f6;
        }
        &--signed {
            background-color: #e0eefa;
        }
        &--completed {
            background-color: rgba(159, 201, 91, 0.27);
        }
    }

    .merchant-pre-onboarding-details-page__history-status-inner {
        display: flex;
        align-items: center;

        .merchant-pre-onboarding-details-page__history-status-inner-icon {
            margin-right: 7px;
        }
    }

    .merchant-pre-onboarding-details-page__full-info {
        margin-top: 30px;

        .merchant-pre-onboarding-details-page__full-info-inner {
            margin: 35px;
        }
    }

    .merchant-pre-onboarding-details-page__full-info-section {
        display: flex;
        .merchant-pre-onboarding-details-page__full-info-section-title {
            width: 250px;
            font-size: 16px;
            font-weight: 600;
        }
        .merchant-pre-onboarding-details-page__full-info-section-content {
            flex: 1 1 auto;
        }
        .merchant-pre-onboarding-details-page__full-info-section-content-subsection {
            padding-bottom: 10px;
        }
    }
}
