$rowIndent: 32px;

.merchant-custom-prices-form {
    hr {
        margin: 20px 0;
    }
    .merchant-custom-prices-form-section__sub-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .merchant-custom-prices-form__ddl-section {
        .merchant-custom-prices-form__ddl-section-ddl {
            max-width: 400px;
            &.merchant-custom-prices-form__ddl-section-ddl--highlighted {
                color: rgb(26, 73, 109);
                background-color: #eaf3fb;
                font-weight: 700;
            }
            margin-bottom: 25px;
        }
    }

    .merchant-custom-prices-form__section {
        &.merchant-custom-prices-form__section--highlighted {
            background-color: rgba(229, 240, 251, 0.3);
        }
    }

    .merchant-custom-prices-form__accordian-header {
        font-size: 14px;
        color: #1a496d;
        font-weight: 700;
        background-color: transparent;
    }
    .merchant-custom-prices-form__accordian-inner {
        padding: 20px $rowIndent;
    }

    .merchant-custom-prices-form-section__price-row {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &.merchant-custom-prices-form-section__price-row--highlighted {
            background-color: white;
            margin-left: -$rowIndent;
            margin-right: -$rowIndent;
            padding: 10px $rowIndent;

            font-weight: 700;
        }

        .merchant-custom-prices-form-section__price-row-left {
            flex: 1 1 0;
        }
        .merchant-custom-prices-form-section__price-row-center {
            flex: 1 1 0;
        }
        .merchant-custom-prices-form-section__price-row-right {
            flex: 1 1 0;
            padding-left: 40px;

            i {
                color: #797979;
                font-size: 14px;
            }
        }
    }
}
