@import "../../../scss/partials/all.scss";

.terms-template-version-form {
    .terms-template-version-form__version-container {
        display: flex;
        margin-bottom: 20px;
    }
    .terms-template-version-form__input {
        flex: 1 0 0;
        margin-right: 20px;
    }

    .terms-template-version-form__input:last-child {
        margin-right: 0;
    }
}
