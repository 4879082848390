.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 6px;

    .data-table__divider {
        border-bottom: 1px solid #d7d7d7;
        padding: unset;
    }
    thead {
        color: #767679;
    }
    tr th {
        width: 240px;
        font-weight: bold;
    }
    tr td {
        font-weight: 100;
    }
}
