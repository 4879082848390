.email-base-template-legend {
    margin-bottom: 20px;

    .legend {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        > * {
            flex: 1 1 0;
        }

        .text {
            padding-left: 20px;
        }
    }
}
