#terms-template-page {
    .terms-template-page__actions {
        background-color: #f3f3f3;
        padding: 20px 20px;

        .terms-template-page__actions-buttons {
            display: flex;
            > * {
                flex: 1 1 0;
                margin-left: 20px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .terms-template-page__type-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .terms-template-page__template-type-ddl {
            flex: 3 1 0px;
        }
    }

    .terms-template-page__version-container {
        margin-bottom: 20px;

        .terms-template-page__version-select-container {
            display: flex;
            align-items: flex-end;
            .terms-version-container__version-ddl-container {
                flex: 1 1 auto;
            }

            .terms-template-page__version-remove {
                flex: 1 1 auto;
                max-width: 200px;
                margin-left: 30px;
                margin-bottom: 12px;
                color: red;
                text-decoration: underline;
            }
        }
    }

    .terms-template-page__actions-show-example-btn {
        align-self: flex-end;
    }

    .terms-template-page__example-form {
        margin-bottom: 20px;
    }

    .terms-template-page__release-container {
        display: flex;
        justify-content: flex-end;
    }
}
