@import "../../../scss/partials/all.scss";

.code-editor {
    .editor {
        border: 1px solid lightgrey;
    }
    .editor-textarea {
        outline: 0;
    }
}
