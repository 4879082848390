#delete-user-page {
    .users {
        min-height: 100px;
    }

    .user {
        padding: 20px 0;
        border-bottom: solid black 1px;
        display: flex;
        align-items: center;

        .name {
            font-size: 20px;
            flex: 1 1 0;
        }
        .email {
            font-size: 20px;
            flex: 1 1 0;
        }
    }
}
