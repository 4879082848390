﻿@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400");

/* Fonts */
$font-stack: "Open Sans", sans-serif;

/****** Component sizes *******/
/* Message box*/
$center-form-width-desktop: 475px;
$center-form-width-desktop-merchant: 560px;
$center-form-width-desktop-partner: 560px;
$message-box-width-desktop: 640px;
$message-box-input-width-desktop: 306px;
$header-height: 50px;

$nav-bar-height: 65px;
$nav-menu-height: 65px;

$contact-form-width: 630px;

$sidebar-width: 120px;
$sidebar-hidden: 10px;
$sidebar-visible: 10px;

/*Floating lables*/
$base--line-height: 24px;
$base--spacing: 30px;
$color--payment-gray: #999999;

$footer-height: 86px;
$footer-height-mobile: 124px;

$sidebar-width: 120px;

$main-container-width: 736px;
$main-container-top-margin: 80px;
$submenu-width: 250px;

$infobox-min-height: 110px;
$infobox-padding-right: 100px;

$sw-btn-height: 48px;

$input-padding-x: 0.75rem;
$input-padding-y: 0.65rem;
$input-border-radius: 7px;
$input-size: 0.65rem;
$input-line-height: 1.4rem;
$input-top: -0.3rem;

/* Responsive breakpoints*/
$breakpoint-large: 992px;
$breakpoint-tables: 768px;
$breakpoint-mobile: 576px;
$breakpoint-mobile-m1: 575px;
