#plus-sell-sessions {
    min-height: 200px;

    .plus-sell-sessions__webshop-select {
        margin-bottom: 25px;
    }

    .plus-sell-sessions__fetch-more {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .plus-sell-sessions__spinner {
        height: 200px;
    }

    .plus-sell-sessions__sessions-filter-checkbox {
        &:first-of-type {
            margin-top: 10px;
        }
        margin-bottom: unset;
    }
}
