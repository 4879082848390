.email-template-image-display {
    .image-container {
        width: 330px;
        background-color: white;
        display: flex;
        border: solid 5px #eee;
        margin: 0 10px 10px 0;

        &.green {
            border-color: green;
        }
        &.blue {
            border-color: blue;
        }
        &.red {
            border-color: red;
        }

        .info-column {
            padding: 10px;
            flex: 1 1 0;

            .name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        .image {
            background-color: #eee;
            height: 150px;
            width: 150px;

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
}
