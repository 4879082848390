.page-section {
    &.page-section--content-indent {
        .page-section-content {
            margin-left: 40px;
        }
    }
    .page-section-body {
        padding: 0 !important;
    }
    .page-section-inner {
        padding: 45px 60px;
        color: #1a496d;
        font-size: 14px;
    }
    .page-section-title {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
