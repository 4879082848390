#ensure-onboarding-page {
    .page-title h5 {
        font-weight: 300;
    }

    .spinner-container {
        min-height: 60px;
    }

    .onboarding-data-container {
        display: flex;
        align-items: center;

        padding: 0 5%;

        .merchant-id {
            font-size: 25px;
            flex-grow: 1;
        }

        .swiipe-standard-button {
            height: 35px;   
        }

        .text-btn, .swiipe-standard-button {
            width: 130px;
        }
    }

    .user-input-container {
        .header {
            font-size: 25px;
        }
    }
}
