@import "../../scss/partials/_all.scss";

.webshop-asset-edit-page {
    .webshop-asset-editor textarea {
        min-height: 300px;
    }

    .platform-name {
        font-size: 20px;
        align-self: center;
    }

    .button-container {
        display: flex;
        > * {
            flex: 1 1 0;
            margin: 0 20px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .fast-update-time {
        span {
            background-color: #ddd;
            border-radius: 5px;
            padding: 10px;
        }

        margin-top: 20px;
        font-size: 20px;
        text-align: center;
    }
}
