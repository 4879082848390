#sentsmses-page {
    .heading {
        font-size: 18px;
        font-weight: 600;
        color: #1a496d;
    }

    .search {
        max-width: 400px;
        padding: 0 !important;
        margin: 20px auto;
    }

    .no-smses {
        font-size: 16px;
    }

    .smses-table {
        width: 100%;

        tr {
            border-bottom: 1px solid #dfdfdf;

            th {
                font-size: 1.15em;
                color: #1a496d;
            }

            td {
                font-weight: 300;
                font-weight: 1.1em;

                &.sent-to {
                    span {
                        display: block;

                        &.name {
                            font-weight: 600;
                        }
                    }
                }
            }

            th,
            td {
                padding: 14px 5px;
            }
        }

        .actions {
            display: flex;
            > * {
                flex: 1 1 0;
                margin: 0 20px;
            }

            .action-btn {
                height: 35px;
                min-width: 110px;
                font-weight: 500;
            }
        }

        .sent-text {
            color: #797676;
            text-align: center;
            display: block;
        }
    }
}
