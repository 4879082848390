.google-analytics-overrides {
    .google-analytics-overrides__disclaimer {
        font-size: 15px;
        span {
            font-weight: 100;
        }
    }
}

.ga-events-overview {
    font-size: 15px;

    .ga-events-overview__title {
        font-size: 16px;
        font-weight: 700;
    }

    table {
        width: 100%;

        td, th {
            border: 1px solid #ddd;
        }

        tr {
            &:nth-child(even) {
                background-color: #f2f2f2;
            }
            &:hover {
                background-color: #ddd;
            }
        }

        td {
            padding: 7px 10px;
        }
        
        th {
            padding: 12px 10px;
            text-align: left;
            background-color: #1b4a6d;
            color: white;
        }
    }
}