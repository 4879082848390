.manage-merchant-invoicing-page {
    .manage-merchant-invoicing-page__title-container {
        display: flex;
        width: 100%;
        align-items: center;
        .manage-merchant-invoicing-page__title {
            font-size: 28px;
            color: #1a496d;
            flex: 1 1 auto;
        }
        .manage-merchant-invoicing-page__save-top {
            padding: 0 80px;
            font-size: 16px;
        }
    }

    .manage-merchant-invoicing-page__data-row {
        padding-bottom: 15px;
        display: flex;

        &:last-child {
            padding-bottom: 0;
        }

        .manage-merchant-invoicing-page__data-row-left {
            flex: 0 0 250px;
            display: flex;
            align-items: center;
        }
        .manage-merchant-invoicing-page__data-row-right {
            flex: 1 1 auto;
            display: flex;
        }

        .manage-merchant-invoicing-page__data-row-input {
            width: 280px;
        }
    }
    .manage-merchant-invoicing-page__trial-expiration-value {
        width: 100px;
    }
    .manage-merchant-invoicing-page__trial-expiration-container {
        position: relative;
        .manage-merchant-invoicing-page__trial-expiration-edit {
            text-decoration: underline;
            cursor: pointer;
            margin-left: 80px;
        }
        .manage-merchant-invoicing-page__trial-expiration-date-picker {
            position: absolute;
            top: 30px;
            left: 80px;
            z-index: 1;
            &--hide {
                display: none;
            }
        }
    }
    .manage-merchant-invoicing-page__section-prices {
        padding-left: 0;
        padding-right: 0;
    }
    .manage-merchant-invoicing-page__section-prices-title {
        padding-left: 60px;
        padding-right: 60px;
    }

    .manage-merchant-invoicing-page__footer {
        padding-top: 25px;
        padding-bottom: 25px;

        .manage-merchant-invoicing-page__footer-container {
            display: flex;
            justify-content: flex-end;
            .manage-merchant-invoicing-page__footer-container-save {
                padding: 0 80px;
                font-size: 16px;
            }
        }
    }
}
